import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { useUser } from "../../auth/hooks"
import PageHeader from "../../components/PageHeader"

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Heading = styled.h2`
  margin: 20px 0px;
`

const Description = styled.p`
  line-height: 22px;

  .link {
    color: ${({ theme }) => theme.palette.orangePeel};
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  padding: 0px 20px;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.white};
  text-align: center;
  * {
    max-width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    * {
      width: 550px;
    }
  }
`

const ContractSignatureProcessing = (): JSX.Element => {
  const { user } = useUser()

  const trainingCompleted = user?.profile?.trainingCompleted

  return (
    <Wrapper>
      <PageHeader title="My Profile" />

      <ContentWrapper>
        <img
          src="/images/dashboard/icon-signature-black.svg"
          alt="Yoco Reseller - contract document icon"
          width={30}
          height={40}
        />
        <Heading>Contract is being processed.</Heading>
        <Description>
          Thanks for signing the Yoco Reseller Contract. Keep an eye on your
          inbox, we&apos;ll email you your fully signed contract shortly.
        </Description>

        {user && !trainingCompleted ? (
          <Description>
            In the meantime, you can{" "}
            <Link to="/dashboard/training/" className="link">
              complete your training
            </Link>{" "}
            to become an official Yoco Reseller.
          </Description>
        ) : null}
      </ContentWrapper>
    </Wrapper>
  )
}

export default ContractSignatureProcessing
